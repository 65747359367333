import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { makeStyles } from '@material-ui/styles';

import Footer from './footer';
import Navbar from './Navbar';
import { BREAKPOINTS } from '../styles/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 20px)',
  },
  mainWrap: {
    width: '100%',
    backgroundColor: '#fff',
    marginTop: 54,
    marginBottom: 10,
    [BREAKPOINTS.md]: {
      marginTop: BREAKPOINTS.topbar,
    },
  },
  main: {
    marginBottom: 10,
    [BREAKPOINTS.md]: {},
  },
}));

const Layout = ({ children, hideTitle }) => {
  const classes = useStyles();
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              company
            }
          }
        }
      `}
      render={(data) => (
        <div className={classes.root}>
          <Navbar hideTitle={hideTitle} />
          <div className={classes.toolbarMixin} />
          <div className={classes.mainWrap}>{children}</div>
          <Footer siteTitle={data.site.siteMetadata.company} />
        </div>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
